<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <CompaniesCover />
    <CompOverview />
    <PartnersComp />
    <Partnership />
  </div>
</template>

<script>
// @ is an alias to /src
import CompaniesCover from '@/components/companies/CompaniesCover.vue'
import CompOverview from '@/components/companies/CompOverview.vue'
// import Slider from '@/components/home/Slider.vue'
import PartnersComp from '@/components/companies/PartnersComp.vue'
import Partnership from '@/components/companies/Partnership.vue'


export default {
  name: 'Home',
  components: {
    CompOverview,
    CompaniesCover,
    PartnersComp,
    Partnership,
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>