<template>
  <div class="wrapper">
    <div class="content">
      <h2>{{ $t('about_title')}}</h2>
      <div class="text">
        <div class="paragraph">
          {{ $t('about_us_body')}}
        </div>
        <div class="paragraph">
          {{ $t('about_us_body2')}}
        </div>
        <!-- <div class="paragraph">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
          Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
          Donec nec justo eget felis facilisis fermentum. Aliquam porttitor
          mauris sit amet orci. Aenean dignissim pellentesque felis.
        </div> -->
      </div>
      <div class="boxes">
        <div class="card1">
          <div class="img-cover">
            <img src="@/assets/images/walking_in_nature.jpg" alt="" />
          </div>
          <div class="inner-card">
            <div class="icon">
              <img src="@/assets/images/ico-peripatetic.svg" alt="" />
            </div>
            <div class="text-box">
              <h3>{{ $t('peripatetic_title')}}</h3>
              <p>
                {{ $t('peripatetic_body')}}
              </p>
              <div v-show-slide="featuresOpen1" class="features">
                
                {{ $t('peripatetic_body_text2')}} <br> <br>
                {{ $t('peripatetic_body_text3')}}<br> <br>
                {{ $t('peripatetic_body_text4')}}
                  <!-- <p >
                This all the extra text we wanna be adding man, but right now I
                don't really know what to say so Im gonna copy a Lorem ipsum to
                see how it looks.
              </p>
              <p >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
                finibus felis, sed auctor nulla. Donec convallis sodales risus,
                nec mollis ipsum lacinia vel. Fusce in justo nec elit feugiat
                vulputate blandit et lacus. Nulla mollis tempor magna, sed
                pharetra orci suscipit eget. Vestibulum placerat nulla sit amet
                erat bibendum ornare. Quisque enim nunc, cursus pulvinar
                suscipit at, vulputate et orci. Maecenas aliquet rhoncus auctor.
                In quis venenatis felis.
              </p> -->
              </div>
              <button @click="toggleFeatures1" class="toggle-features">
              {{ featuresOpen1 ? $t('see_less') : $t('see_more') }}
            </button>
            </div>
            
          </div>
        </div>
        <div class="card">
          <div class="img-cover">
            <img src="../../assets/images/tree_sm.jpg" alt="" />
          </div>
          <div class="inner-card">
            <div class="icon">
              <img src="@/assets/images/ico-nature-fun.svg" alt="" />
            </div>
            <div class="text-box">
              <h3>{{ $t('nature&fun_title')}}</h3>
              <p>
                {{ $t('nature&fun_body')}}
              </p>
              <div v-show-slide="featuresOpen2" class="features">
                {{ $t('nature&fun_body_text2')}} <br><br>
                {{ $t('nature&fun_body_text3')}}
                  <!-- <p >
                This all the extra text we wanna be adding man, but right now I
                don't really know what to say so Im gonna copy a Lorem ipsum to
                see how it looks.
              </p>
              <p >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
                finibus felis, sed auctor nulla. Donec convallis sodales risus,
                nec mollis ipsum lacinia vel. Fusce in justo nec elit feugiat
                vulputate blandit et lacus. Nulla mollis tempor magna, sed
                pharetra orci suscipit eget. Vestibulum placerat nulla sit amet
                erat bibendum ornare. Quisque enim nunc, cursus pulvinar
                suscipit at, vulputate et orci. Maecenas aliquet rhoncus auctor.
                In quis venenatis felis.
              </p> -->
              </div>
            </div>
            <button @click="toggleFeatures2" class="toggle-features">
              {{ featuresOpen2 ? $t('see_less') : $t('see_more') }}
            </button>
          </div>
        </div>
        <div class="card">
          <div class="img-cover">
            <img src="../../assets/images/Agile-learning_sm.jpg" alt="" />
          </div>
          <div class="inner-card">
            <div class="icon">
              <img src="@/assets/images/ico-agilelearning.svg" alt="" />
            </div>
            <div class="text-box">
              <h3>{{ $t('agile_learning_title')}}</h3>
              <p>
                {{ $t('agile_learning_body')}}
              </p>
              <div v-show-slide="featuresOpen3" class="features">
                {{ $t('agile_learning_body_text2')}} <br><br>
                {{ $t('agile_learning_body_text3')}} <br><br>
                {{ $t('agile_learning_body_text4')}}
                  <!-- <p >
                This all the extra text we wanna be adding man, but right now I
                don't really know what to say so Im gonna copy a Lorem ipsum to
                see how it looks.
              </p>
              <p >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
                finibus felis, sed auctor nulla. Donec convallis sodales risus,
                nec mollis ipsum lacinia vel. Fusce in justo nec elit feugiat
                vulputate blandit et lacus. Nulla mollis tempor magna, sed
                pharetra orci suscipit eget. Vestibulum placerat nulla sit amet
                erat bibendum ornare. Quisque enim nunc, cursus pulvinar
                suscipit at, vulputate et orci. Maecenas aliquet rhoncus auctor.
                In quis venenatis felis.
              </p> -->
              </div>
              <button @click="toggleFeatures3" class="toggle-features">
              {{ featuresOpen3 ? $t('see_less') : $t('see_more') }}
            </button>
            </div>
            
          </div>
        </div>
      </div>
      <!-- <div class="image">
               <img src="@/assets/images/tree_sm.jpg" alt="">
           </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      featuresOpen1: false,
      featuresOpen2: false,
      featuresOpen3: false,
    };
  },
  methods: {
    toggleFeatures1() {
      this.featuresOpen1 = !this.featuresOpen1;
    },
    toggleFeatures2() {
      this.featuresOpen2 = !this.featuresOpen2;
    },
    toggleFeatures3() {
      this.featuresOpen3 = !this.featuresOpen3;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  background-image: linear-gradient(0deg, white 35%, #e4f2f2 50%, white 100%);
  .content {
    .text {
      display: flex;
      justify-content: space-between;
      text-align: left;
      @media screen and (max-width: 530px) {
        flex-direction: column;
      }
      .paragraph {
        @media screen and (max-width: 530px) {
          width: 100%;
          margin-bottom: 30px;
        }
        width: 48%;
      }
    }
    .boxes {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 920px) {
        flex-direction: column;
      }
      .card,.card1,.card2,.card3 {
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        // border: solid 2px #395359;
        align-items: center;
        // padding: 15px;
        height: fit-content;
        width: 31%;
        transition: all 0.5s;
        box-shadow: 0px 0px 4px 2px rgba($color: #395359, $alpha: 0.3);
        @media screen and (max-width: 920px) {
          margin: auto;
          max-width: 400px;
          width: 100%;
          margin-bottom: 30px;
        }
        .img-cover {
          width: 100%;
          img {
            object-fit: cover;
            width: 100%;
            border-radius: 15px 15px 0 0;
            height: 125px;
          }
        }
        .inner-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px;
        }
        .icon {
          width: 80px;
          height: 60px;
          img {
            height: 100%;
          }
        }
        .toggle-features {
          margin-top: 15px;
        }
        .text-box {
          text-align: center;
          margin-top: 25px;
          // display: flex;
          // flex-direction: column;
          h3 {
            margin-top: 0px;
          }
        }
        &:hover {
          background-color: white;
        }
      }
    }
    .image {
      width: 100%;
      max-height: 200px;
      display: flex;
      margin: 50px 0;
      img {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
