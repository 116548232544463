<template>
  <div class="wrapper">
    <div class="white-waves">
      <img src="@/assets/images/white-lines.svg" alt="" />
    </div>
    <div class="content">
      <h1>{{ $t('institutions_title')}}</h1>
      <div class="text">
        <div class="paragraph">
          {{ $t('title_body_institutions')}}
        </div>
      </div>
        <h2 class="divider-title">{{ $t('title_courses')}}</h2>
      <div class="card">
        <div class="left-side">
            <div class="possible">
                <h3>{{ $t('title_tailored_courses')}}</h3>
                <p>{{ $t('tailored_courses_body')}}</p>
                <button class="green">Get in touch</button>
            </div>
        </div>
        <div class="right-side">
            <img src="@/assets/images/outside_render_sm.jpg" alt="" />
        </div>
      </div>
      <!-- <div class="addition">
        <div class="body">
          <div>Anything is possible</div>
          Let us know what you want and we can make it happen. We would need at
          least 2 months prior notivce to make the arrangements.
        </div>
        <div class="picture">
        </div>
      </div> -->
      <!-- <div class="image">
               <img src="@/assets/images/tree_sm.jpg" alt="">
           </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "InstitutionsOverview",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  position: relative;
  background-image: linear-gradient(0deg, white 35%, #e4f2f2 50%, white 100%);
  .white-waves {
    position: absolute;
    z-index: 0;
    top: 30px;
    left: 0;
    width: 100vw;
    img {
      width: 100%;
      max-height: 600px;
    }
  }
  .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    position: relative;
    .text {
      display: flex;
      justify-content: center;
      text-align: center;
      @media screen and (max-width: 700px) {
        text-align: left;
      }
      .paragraph {
        width: 75%;
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }
    .divider-title {
        margin-top: 50px;

    }
    .card {
        box-shadow: 0px 0px 4px 2px rgba($color: #395359, $alpha: 0.3);
        display: flex;
        justify-content: space-around;
        border-radius: 15px;
        width: 80%;
        overflow: hidden;
        position: relative;
        height: 250px;
        margin-bottom: 25px;
        @media screen and (max-width: 700px) {
          flex-direction: column-reverse;
          height: auto;
          width: 100%;
        }
        .right-side {
            width: 55%;
            @media screen and (max-width: 700px) {
              width: 100%;
            }
        }
        .left-side {
            width: 45%;
            display: flex;
            flex-direction: column;
            padding: 15px 15px 15px 25px;
            @media screen and (max-width: 700px) {
              width: 80%;
            }
            .possible {
                text-align: left;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                button {
                    width: 200px;
                    box-shadow: 0px 0px 3px 1px rgba($color: #000000, $alpha: 0.3);
                    @media screen and (max-width: 700px) {
                      bottom: 41%;
                      left: 20%;
                    }

                    
                    }
            }
        }
        img {
            width: 100%;
            height: 100%;
            border-radius: 0px 15px 15px 0;
            @media screen and (max-width: 700px) {
              border-radius: 15px 15px 0px 0px;
            }
        }
    }
    .addition {
      display: flex;
      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
      .body {
        width: 30%;
        text-align: left;
        @media screen and (max-width: 700px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
      .picture {
        width: 65%;
        border-radius: 15px;
        overflow: hidden;
        @media screen and (max-width: 700px) {
          width: 100%;
        }
        img {
          max-height: 100%;
          max-width: 100%;
          object-fit: cover;
        }
      }
    }
    // .image{
    //     width: 100%;
    //     max-height: 200px;
    //     display: flex;
    //     margin: 50px 0;
    //     img{
    //         object-fit: cover;
    //         max-width: 100%;
    //         max-height: 100%;
    //     }
    // }
  }
}
</style>
