<template>
  <div class="wrapperCover">
      <div class="content">
          <h1>{{ $t('about_title')}}</h1>
          <!-- <div class="text">
              <h1 style="margin: 0px;">Learn the <br>natural way</h1>
              <p>We believe in a holistic form of learning, creative, practical and interactive</p>
              <button>Learn more</button>
          </div> -->
      </div>
      <div class="wave">
          <img src="@/assets/images/waves.svg" alt="">
      </div>
  </div>
</template>

<script>
export default {
  name: 'AboutCover',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapperCover{
    background-image: url('../../assets/images/walk-nature_sm.jpg');
    background-size: cover;
    background-position: center;
    height: 350px;
    position: relative;
    align-items: center;
    .content{
        height: 100%;
        width: 100%;
        // background-color: #f2f2f2a2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        // justify-content: flex-end;
        padding-right: 5%;
        // .text{
        //     width: 45%;
        //     text-align: left;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        // }
    }
    .wave{
        position: absolute;
        bottom: -5px;
        left: 0px;
        widows: 100vw;
        img{
            width: 100%;
        }
    }
}
</style>
