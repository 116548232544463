<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <AboutCover />
    <About />
    <Clients />

  </div>
</template>

<script>
// @ is an alias to /src
import AboutCover from '@/components/about/AboutCover.vue'
import About from '@/components/about/About.vue'
// import Slider from '@/components/home/Slider.vue'
import Clients from '@/components/home/Clients.vue'


export default {
  name: 'Home',
  components: {
    About,
    AboutCover,
    Clients,
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>