<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <InstitutionsCover />
    <InstitutionsOverview />
    <InstitutionsComp />
    <InstitutionsPartnership />
  </div>
</template>

<script>
// @ is an alias to /src
import InstitutionsCover from '@/components/institutions/InstitutionsCover.vue'
import InstitutionsOverview from '@/components/institutions/InstitutionsOverview.vue'
// import Slider from '@/components/home/Slider.vue'
import InstitutionsComp from '@/components/institutions/InstitutionsComp.vue'
import InstitutionsPartnership from '@/components/institutions/InstitutionsPartnership.vue'


export default {
  name: 'Home',
  components: {
    InstitutionsCover,
    InstitutionsOverview,
    InstitutionsComp,
    InstitutionsPartnership,
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>