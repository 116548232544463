<template>
  <div class="wrapper">
      <div class="wave">
          <img src="@/assets/images/waves-inv.svg" alt="">
      </div>
      <div class="content">
          <h2>{{ $t('running_programs') }}</h2>
          <div class="program">
            <div class="info">
                <div class="date">
                    01 - 26 {{ $t('month') }}
                </div>
                <div class="title">
                    Insituto Cervantes
                </div>
                <div class="description">
                    {{ $t('running_program_cervantes') }}
                </div>
            </div>
            <div class="logo">
                <img src="@/assets/images/cervantes-logo.jpg" alt="">
            </div>
          </div>
          <div class="program">
              <div class="logo">
                <img src="@/assets/images/six-sigma-logo.jpg" alt="">
            </div>
            <div class="info">
                <div class="date">
                    21 - 26 {{ $t('month') }}
                </div>
                <div class="title">
                    The lean six sigma company
                </div>
                <div class="description">
                    {{ $t('running_program_sigma') }}
                </div>
            </div>
            
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'InstitutionsComp',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
          background-image: linear-gradient(0deg, white 10%, #e4f2f2 30%, white 100%);
        .wave{
        // position: absolute;
        // bottom: -5px;
        // left: 0px;
        width: 100vw;
        
        img{
            width: 100%;
        }
    }
    .content{
        .program{
            display: flex;
            margin-bottom: 50px;
            @media screen and (max-width: 830px)  {
                    flex-direction: column;
                    &:nth-child(even){
                        flex-direction: column-reverse;
                    }
                }
            .info{
                padding: 10px;
                border-radius: 15px;
                border: 2px solid #56828c;
                width: 50%;
                @media screen and (max-width: 830px)  {
                    width: calc(100% - 20px);
                }
                .date{
                    border: 2px solid #56828c;
                    background-color: #395359;
                    border-radius: 5px;
                    color: white;
                    padding: 2px 15px ;
                    width: fit-content  ;
                }
                .title{
                    font-size: 1.2rem;
                    text-align: left;
                    margin: 15px 0;
                }
                .description{
                    font-size: 0.9rem;
                    text-align: left;
                    margin-bottom: 10px;
                }
            }
            .logo{
                padding: 20px;
                width: calc(50% - 40px);
                height: 150px;
                @media screen and (max-width: 830px)  {
                    width: calc(100% - 40px);
                }
                img{
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>
