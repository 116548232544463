<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <StudentsCover/>
    <StudentsOverview/>
    <SignUp/>
  </div>
</template>

<script>
// @ is an alias to /src
import StudentsCover from '@/components/students/StudentsCover.vue'
import StudentsOverview from '@/components/students/StudentOverview.vue'
import SignUp from '@/components/students/SignUp.vue'

export default {
  name: 'Home',
  components: {
    StudentsCover,
    StudentsOverview,
    SignUp

  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>
    