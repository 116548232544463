<template>
  <div class="wrapper">
      <div class="content">
          <div class="cards">
          <div class="card">
              <div class="icon">
                   <img src="@/assets/images/ico-student.svg" alt="">
              </div>
              <div class="body">
                  <div class="text">{{ $t('next_one') }}</div>
                <router-link :to="'/' + $i18n.locale + '/contact'"><button class="light">{{ $t('in_touch') }}</button></router-link>
              </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Partnership',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
    background: linear-gradient(90deg, #31928f85, #56828c86), url('../../assets/images/magna_sm.jpg');
    //   -webkit-filter: blur(5px);
    //     -moz-filter: blur(5px);
    //     -o-filter: blur(5px);
    //     -ms-filter: blur(5px);
        backdrop-filter: blur( 10.0px );
-webkit-backdrop-filter: blur( 10.0px );
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    .content{
        // height: 200px;
        padding-top: 80px;
        padding-bottom: 80px;
        .cards{
            display: flex;
            justify-content: space-evenly;
            .card{
            background-color: white;
            border-radius: 10px;
            border: 1px solid #838c3f;
            width: 400px;
            &.company{
                border: 1px solid #56828c;
                .icon{
                  top: -7px;  
                }
            }
            .icon{
                margin: auto;
                position: relative;
                top: -8px;
                width: 80px;
                img{
                    width: 100%;
                }
            }
            .body{
                margin-bottom: 15px;
            }
            .text {
                margin-bottom: 20px;
                font-weight: 500;
            }
        }
        }
    }
}
</style>
