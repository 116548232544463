<template>
  <div class="home">
    <ContactCover />
    <Form :wave="false"/>
    <Map />
  </div>
</template>

<script>
// @ is an alias to /src
import ContactCover from '@/components/contact/ContactCover.vue'
import Form from '@/components/home/Form.vue'
import Map from '@/components/home/Map.vue'

export default {
  name: 'Home',
  components: {
      ContactCover,
    Form,
    Map
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>
