<template>
  <div class="wrapper">
      <div class="white-waves">
          <img src="@/assets/images/white-lines.svg" alt="">
      </div>
      <div class="content">
           <h1>{{ $t('students_title')}}</h1>
           <div class="text">
               <div class="paragraph">
                {{ $t('title_body_students')}}
               </div>

           </div>
  <h2 class="header">{{ $t('running_programs') }}</h2>
          <div class="program">
            <div class="info">
                <div class="date">
                    01 - 26 {{ $t('month') }}
                </div>
                <div class="title">
                    Insituto Cervantes
                </div>
                <div class="description">
                    {{ $t('running_program_cervantes') }}
                </div>
            </div>
            <div class="logo">
                <img src="@/assets/images/cervantes-logo.jpg" alt="">
            </div>
          </div>
          <div class="program">
              <div class="logo">
                <img src="@/assets/images/six-sigma-logo.jpg" alt="">
            </div>
            <div class="info">
                <div class="date">
                    21 - 26 {{ $t('month') }}
                </div>
                <div class="title">
                    The lean six sigma comp
                </div>
                <div class="description">
                    {{ $t('running_program_sigma') }}
                </div>
            </div>
            
          </div>
          <!-- <div class="soon">Keep updated to New programs coming soon.</div> -->
           <!-- <div class="image">
               <img src="@/assets/images/tree_sm.jpg" alt="">
           </div> -->
      </div>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
    position: relative;
    background-image: linear-gradient(0deg, white 35%, #e4f2f2 50%, white 100%);
    .white-waves{
        position: absolute;
        z-index: 0;
        top: 30px;
        left: 0;
        width: 100vw;
        img{
            width: 100%;
                max-height: 600px;
        }
    }
    .content{
        position: relative;
        .text{
            display: flex;
            justify-content: center;
            text-align: center;
            @media screen and (max-width: 700px)  {
                text-align: left;
            }
            .paragraph{
                width: 75%;
                @media screen and (max-width: 700px)  {
                width: 100%;
            }
            }
        }
        .header {
            margin: 30px 0 30px 0;
        }
        .program{
            display: flex;
            margin-bottom: 50px;
            @media screen and (max-width: 830px)  {
                    flex-direction: column;
                    &:nth-child(even){
                        flex-direction: column-reverse;
                    }
                }
            .info{
                padding: 10px;
                border-radius: 15px;
                // border: 2px solid #56828c;
                border: none;
                box-shadow: 0px 0px 4px 2px rgba(57, 83, 89, 0.3);
                width: 50%;
                @media screen and (max-width: 830px)  {
                    width: calc(100% - 20px);
                }
                .date{
                    border: 2px solid #56828c;
                    background-color: #395359;
                    border-radius: 5px;
                    color: white;
                    padding: 2px 15px ;
                    width: fit-content  ;
                }
                .title{
                    font-size: 1.2rem;
                    text-align: left;
                    margin: 15px 0;
                }
                .description{
                    font-size: 0.9rem;
                    text-align: left;
                    margin-bottom: 10px;
                }
            }
            .logo{
                padding: 20px;
                width: calc(50% - 40px);
                height: 150px;
                @media screen and (max-width: 830px)  {
                    width: calc(100% - 40px);
                }
                img{
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .soon {
            margin: 30px 0;
            font-weight: 500;
            font-size: 1.1em;
        }
        //   .program{
        //     display: flex;
        //     margin-bottom: 50px;
        
        //     .info{
        //         padding: 10px;
        //         border-radius: 15px;
        //         border: 2px solid #56828c;
        //         width: 50%;
        //         .date{
        //             border: 2px solid #56828c;
        //             background-color: #395359;
        //             border-radius: 5px;
        //             color: white;
        //             padding: 2px 15px ;
        //             width: fit-content  ;
        //         }
        //         .title{
        //             font-size: 1.2rem;
        //             text-align: left;
        //             margin: 15px 0;
        //         }
        //         .description{
        //             font-size: 0.9rem;
        //             text-align: left;
        //             margin-bottom: 10px;
        //         }
        //     }
        //     .logo{
        //         padding: 20px;
        //         width: calc(50% - 40px);
        //         height: 150px;
        //         img{
        //             height: 100%;
        //             object-fit: contain;
        //         }
        //     }
        // }
        // .image{
        //     width: 100%;
        //     max-height: 200px;
        //     display: flex;
        //     margin: 50px 0;
        //     img{
        //         object-fit: cover;
        //         max-width: 100%;
        //         max-height: 100%;
        //     }
        // }
    }
}
</style>
