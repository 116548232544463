<template>
  <div>
      <div class="container">
          
          <div class="buttons">
              <div>
                  Thanks for your submission / Gracias por tu mensaje 
              </div>
              <!-- <button @click="change('es')">
                  Español
              </button>
              <button @click="change('en')">
                  English
              </button> -->
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Redirect',
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
    //  mounted () {
    //     //  console.log("base redirect")
    //     //  this.$router.push('es')
    //  },
    //  methods: {
    //   change(lang){
    //       this.$router.push(lang)
    //   }
//   }
}
</script>

<style scoped lang="scss">
    .container{
        padding-top: 200px; 
        padding-bottom: 200px;
        background-image: url('../assets/images/aula_sm.jpg');
        background-size: cover;
        background-position: center;
        .buttons{
            padding: 20px 30px;
            background-color: rgba(255, 255, 255, 0.603);
            button{
                margin: 5px;
            }
        }
    }
</style>